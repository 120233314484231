@import "./variables";
@import "./mediaquery";

.users {
  height: 100%;
  width: 100%;
  padding: 1em;

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;

    .dtitle{
      display: flex;
      margin: 10px 0 10px 0;

      .search{
        margin: auto 0 auto auto;
        border: grey solid 1px;
        border-radius: 3px;

        input{
          margin-right: 5px;
          background-color: white;
          border-radius: 4px;
          border: none;
          outline: none;
          padding: 4px;
          height: 65%;
          margin: auto 0;
        }

        i{
          background-color: gainsboro;
          border-radius: 2px 2px 0 0;
          padding: 5px;
          margin: auto 0;
        }
      }
    }

    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    .content {
      min-height: 30vh;
    }

    .usrpopup {
      position: absolute;
      right: 0;
      top: 4.5em;
      bottom: 0;
      left: 0;

      .container {
        height: fit-content;
        width: fit-content;
        padding: 1em;
        background-color: $bg;
        margin: auto;
        box-shadow: 2px 2px 10px #60606030;

        .top {
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 10px;
          margin: 10px 0 10px 0;
        }

        p {
          font-size: medium;
          padding: 5px 0 5px 0;
        }

        button {
          padding: 10px;
          background-color: $primary;
          color: white;
          cursor: pointer;
          border: none;
          border-radius: 5px;
        }
      }
    }

    .head,
    .row {
      display: grid;
      grid-template-columns: 4em 1fr 1.2fr 0.5fr 0.5fr;
      gap: 10px;
      padding: 10px;
    }

    .row {
      cursor: pointer;
      margin: 5px 0 5px 0;
    }

    .row:hover {
      background-color: $bg;
    }

    .head {
      background-color: $bg;
    }

    .new {
      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
      .usrinput {
        margin: 10xp 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .usrselect {
        margin: 10xp 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .search_list{
          .s_list:hover{
            text-decoration: underline;
            cursor: pointer;
          }
        }
    }

    // .pagination {
    //   display: grid;
    //   grid-template-columns: auto auto auto;
    //   gap: 10px;
    //   width: fit-content;

    //   h4 {
    //     font-size: large;
    //   }

    //   p {
    //     height: fit-content;
    //     margin: auto;
    //     display: block;
    //     font-size: medium;
    //   }

    //   .fa {
    //     background-color: $accent;
    //     padding: 5px;
    //     cursor: pointer !important;
    //     font-size: small;
    //     border-radius: 6px;
    //     color: white;
    //   }
    //   .fa:hover {
    //     background-color: $primary;
    //   }
    // }

    @include maxwidth(mobile) {
      .row,
      .head {
        grid-template-columns: auto 1fr;
        :nth-child(3) {
          display: none;
        }
        :nth-child(4) {
          display: none;
        }
      }
    }
    @include maxwidth(tablet) {
      .row,
      .head {
        grid-template-columns: auto 1fr;
        :nth-child(3) {
          display: none;
        }
        :nth-child(4) {
          display: none;
        }
      }
    }
  }
}
