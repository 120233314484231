@import "./mediaquery";
@import "./variables";

.stats {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .welcome {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    align-items: center;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }
    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: large;
      font-weight: 560;
      margin-bottom: 10px;
      color: $primarylight;
    }

    input {
      padding: 2px 10px;
      border: 1px solid black;
      border-radius: 5px;
      font-size: large;
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .section {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;

      h3 {
        text-align: center;
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }

      .gauge {
        display: grid;
        grid-template-rows: auto;
        height: 100%;

        .gg {
          display: block;
          margin-top: auto;
        }
      }

      .single {
        display: grid;
        grid-template-columns: auto 1fr;
        place-content: center;
        gap: 10px;
        margin: auto;

        .ts {
          width: 44px;
          height: 44px;
          color: $secondary;
          display: block;
          margin: auto;
        }

        h4 {
          font-size: x-large;
          font-weight: 900;
          color: $accent;
          text-align: right;
          margin-bottom: 4px;
        }
        p {
          font-size: small;
          color: gray;
          text-align: right;
        }
      }

      .perf {
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        gap: 10px;
        height: 100%;
        width: 100%;

        h2 {
          font-size: medium;
          font-weight: 520;
        }

        .hl {
          margin: auto;
          h4 {
            color: red;
            font-size: xx-large;
            font-weight: bolder;
            margin-bottom: 10px;
            text-align: center;
          }
          h5 {
            font-weight: 500;
            color: gray;
            text-align: center;
          }
        }

        .div1auto {
          width: 100%;
          display: grid;
          grid-template-columns: 10px 1fr auto;
          gap: 10px;
          padding: 8px;

          div {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: medium;
          }
          h6 {
            font-size: large;
            font-weight: bolder;
            color: gray;
          }
        }
      }
    }

    .left {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 1em;
        }
      }
    }
    .right {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;
      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 1em;
        }
      }
    }
  }

  .locations{
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    .outer{
      margin-bottom: 4em;  
    }
    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }
    .section{
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;
      margin-top: 1em;

      .div1auto{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;
      }

      h3 {
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }
    }
  }

  .distributions{
    margin-bottom: 1em;
    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .div3equal{
      margin-top: 1em;
    }
    .section{
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;

      .div1auto{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;
      }

      h3 {
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }
    }
  }

  

  .valuechains{
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }
    .section{
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;
      margin-top: 1em;

      .div1auto{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;
      }

      h3 {
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }
    }
  }


  @include maxwidth(mobile) {
    h3 {
      font-size: small !important;
    }
    .taskstats {
      grid-template-columns: 1fr;

      .outer{
        grid-template-columns: 1fr !important;
      }
    }
  }

  @include maxwidth(tablet) {
    h3 {
      font-size: medium !important;
    }
    .taskstats {
      grid-template-columns: 1fr;
    }
  }
}
