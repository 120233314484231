@import "./mediaquery";
@import "./variables";

.stats {
  padding: 1em;
  height: fit-content !important;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .chart {
    background-color: white;
    height: 100%;
    min-height: 250px;
    width: 100%;
    border-radius: 8px;
    padding: 1em;
    position: relative;

    h3 {
      color: gray;
      font-size: medium;
      margin-bottom: 1em;
      font-weight: 600;
    }

    .save {
      position: absolute;
      right: 16px;
      top: 16px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;

      * {
        cursor: pointer;
      }
    }
  }

  .top {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    width: 100%;

    .tp_item {
      padding: 1em;
      background-color: white;
      border-radius: 8px;

      p {
        color: gray;
        font-size: small;
      }

      h1 {
        color: gray;
        font-weight: bold;
        margin: 5px 0 5px 0;
        font-size: xx-large;
      }
    }
  }

  .pies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0 1em 0;
  }

  .middle {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .vmiddle {
    height: fit-content !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0 1em 0;
  }

  .bottom {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  @include maxwidth(mobile) {
    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }
}
